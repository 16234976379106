import request from "@/utils/requestHrms";

// 查询类型字典
export const getQueryFuctions = (params) => {
  return request({
    url: "/api/sys/code/code/item/sys/9302",
    method: "get",
    params,
  });
};

// 查询城市
export const getCityList = (params) => {
  return request({
    url: "/api/sys/wechat/payroll/cityNew/"+params.codeItemId,
    method: "get",
    params,
  });
};

// 查询证书
export const getCityCertificate = (params) => {
  return request({
    url: "/api/sys/wechat/payroll/certificate",
    method: "get"
  });
};

// 查询薪资结果
export const getSalaryResult = (data) => {
  return request({
    url: "/api/sys/wechat/payroll/salaryNew",
    method: "post",
    data,
  });
}

// 查询企业福利详情
export const getWelfareDetail = (params) => {
    return request({
        url: `/api/sys/wechat/payroll/welfare/detail/${ params.userId }/${ params.id }`,
        method: "get"
    });
};

// 反馈
export const submitFeedback = (data) => {
  return request({
    url: "/api/sys/wechat/payroll/feedback",
    method: "post",
    data,
  });
}

// 记录日志
export const postLog = (data) => {
  return request({
    url: "/api/sys/wechat/payroll/log",
    method: "post",
    data,
  });
}
